import React from "react";

const NotFound = () => {
  return (
    <>
      <h1 className="display-2">Wrong page!</h1>
    </>
  );
};

export default NotFound;
